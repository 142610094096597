<template>
  <div>
    <!-- begin:: "Go Back" button -->
    <v-btn text class="mb-2" @click="goBack">
      <v-icon left> mdi-arrow-left </v-icon>
      Regresar a reportes
    </v-btn>
    <!-- end:: "Go Back" button -->

    <div class="white rounded-lg pa-5 py-md-8 px-md-10">
      <template>
        <!-- begin::Page header and title -->
        <div class="card-title">
          <h3 class="card-label">
            Resumen de calificaciones de un estudiante
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aqui se podra visualizar el resumen de las notas obtenidas en
              cada una de las asignaturas y módulos cursados por el
              estudiante.</span
            >
          </h3>
        </div>
        <!-- end::Page header and title -->

        <!-- begin::autocomplete select for student -->
        <v-row>
          <v-col>
            <v-autocomplete
              :label="autocompleteLabel"
              :loading="areStudentsLoading"
              :disabled="
                areStudentsLoading || !students.length || !onlyOneStudent
              "
              rounded
              filled
              hide-details
              clearable
              :items="students"
              item-text="code"
              item-value="code"
              v-model="report.params.code"
              @change="get(report.params.code)"
              :filter="customFilter"
            >
              <!-- begin::selected student item -->
              <template v-slot:selection="{ item, index }">
                <v-chip color="primary" small v-if="index === 0">
                  <span>{{ item.full_name }}</span>
                </v-chip>
              </template>
              <!-- end::selected student item -->
              <template v-slot:item="{ item, index }">
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="font-weight-medium">
                      {{ item.code }}
                    </span>
                    -
                    <span class="text-uppercase font-weight-medium">
                      {{ item.full_name }}
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle class="mt-1">
                    <span>
                      Grado
                      <strong class="ml-1">{{ item.grade }}</strong>
                    </span>
                    <span class="ml-2">
                      Sección
                      <strong class="ml-1">{{ item.section_group }}</strong>
                    </span>
                    <span class="ml-2">
                      Especialidad
                      <strong class="ml-1">{{ item.technical_group }}</strong>
                    </span>
                  </v-list-item-subtitle>
                  <v-divider
                    class=""
                    v-if="index < filteredStudent.length - 1"
                  ></v-divider>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <!-- end::autocomplete select for student -->

        <!-- begin:: Academic and technical score summary container  -->
        <v-container v-if="report.params.code">
          <div class="ma-0">
            <!-- begin:: asignaturas académicas -->
            <v-row class="my-4">
              <p class="ma-0 pa-0 headline mx-4">Académicas</p>
              <v-divider></v-divider>
            </v-row>

            <!-- begin::fallback loading screen - Academics -->
            <div style="height: 30vh" v-if="evaluation.isLoadingAcademic">
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
              >
                <v-col class="text-subtitle-1 text-center" cols="12">
                  Cargando resumen de asignaturas académicas
                </v-col>
                <v-col cols="6">
                  <v-progress-linear
                    color="blue darken-1"
                    indeterminate
                    rounded
                    height="6"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </div>
            <!-- end::fallback loading screen - Academics -->

            <!-- begin:: Academics card component-->
            <SubjectStageSummaryContainer
              v-else
              :evaluation="evaluation.data.academic"
              :selectedStudent="selectedStudent"
            >
            </SubjectStageSummaryContainer>
            <!-- end:: Academics card component-->

            <!-- end:: asignaturas académicas -->
          </div>
          <div class="ma-0">
            <!-- begin:: Módulos -->
            <v-row class="mt-16 mb-4">
              <p class="ma-0 pa-0 headline mx-4">Módulos</p>
              <v-divider></v-divider>
            </v-row>

            <!-- begin::fallback loading screen - Technical -->
            <div style="height: 30vh" v-if="evaluation.isLoadingTechnical">
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
              >
                <v-col class="text-subtitle-1 text-center" cols="12">
                  Cargando resumen de módulos
                </v-col>
                <v-col cols="6">
                  <v-progress-linear
                    color="blue darken-1"
                    indeterminate
                    rounded
                    height="6"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </div>
            <!-- end::fallback loading screen - Technical -->

            <!-- begin:: Technical card component-->
            <SubjectStageSummaryContainer
              v-else
              :evaluation="evaluation.data.technical"
              :selectedStudent="selectedStudent"
            >
            </SubjectStageSummaryContainer>
            <!-- end:: Technical card component-->

            <!-- end:: Módulos -->
          </div>
        </v-container>

        <!-- begin::falback screen when a student hasn't been selected -->
        <v-container v-else>
          <v-row>
            <v-col class="mt-5">
              <p class="headline text-center">
                ¿Desea consultar las calificaciones de un estudiante?
              </p>
              <p class="text-body-1 text-center">
                Ingrese el código de un estudiante para consultar sus
                calificaciones.
              </p>
            </v-col>
          </v-row>
        </v-container>
        <!-- end::falback screen when a student hasn't been selected -->

        <!-- end:: Academic and technical score summary container  -->
      </template>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import SubjectStageSummaryContainer from "@/components/elements/evaluation/SubjectStageSummary.vue";
import studentRepository from "@/repositories/studentRepository";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "EvaluationSummary",
  title: "Resumen de calificaciones | GE ITR",
  components: {
    //The summary container, contains the subject card with scores
    SubjectStageSummaryContainer,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Notas", route: "view_subjects_modules" },
      { title: "Resumen de notas", route: "evaluation_summary" },
    ]);
    this.verifyIfTeacherGuide();
  },
  data() {
    return {
      selectedStudent: {},
      autocompleteLabel: "Código ó nombre del estudiante",
      filteredStudent: [],
      onlyOneStudent: true,
      students: [],
      areStudentsLoading: false,
      report: {
        params: {},
      },
      evaluation: {
        isLoadingAcademic: false,
        isLoadingTechnical: false,
        data: {
          academic: [],
          technical: [],
        },
      },
      teacherGuideStudents: [],
    };
  },
  methods: {
    //Custom filter to search by code and full name
    customFilter(item, queryText, itemText) {
      const searchText = queryText.toLowerCase();
      const codeMatch = item.code.toString().includes(searchText);
      const fullNameMatch = item.full_name.toLowerCase().includes(searchText);
      return codeMatch || fullNameMatch;
    },
    // Function that verifies that if you have a degree assigned as a guide teacher
    verifyIfTeacherGuide() {
      this.areStudentsLoading = true;
      studentRepository
        .verifyIfTeacherGuide(this.currentUserPersonalInfo.id_user)
        .then(({ data }) => {
          if (
            this.getUserType === "Docente académico" ||
            this.getUserType === "Docente técnico"
          ) {
            if (data.exist) {
              // If you are a guide teacher, we go through the groups (in case you are a guide teacher of more than one grade) and send for the students.
              this.teacherGuideStudents = data.data;
              this.teacherGuideStudents.forEach((element) => {
                // If the group information contains the section_group_id, it means that it is an academic students
                if (!!element.groupable.section_group_id) {
                  this.areStudentsLoading = true;
                  studentRepository
                    .getAcademicStudents(element.groupable.id)
                    .then(({ data }) => {
                      this.students.push(...data);
                    })
                    .catch((error) => {
                      throw new Error(error);
                    })
                    .finally(() => {
                      this.areStudentsLoading = false;
                    });
                } else {
                  //If this goes in else bring the technical students
                  this.areStudentsLoading = true;
                  studentRepository
                    .getTechnicalStudent(element.groupable.id)
                    .then(({ data }) => {
                      this.students.push(...data);
                    })
                    .catch((error) => {
                      throw new Error(error);
                    })
                    .finally(() => {
                      this.areStudentsLoading = false;
                    });
                }
              });
            } else {
              this.autocompleteLabel = "No tiene grupos asignados";
              this.areStudentsLoading = false;
            }
          } else {
            this.getStudents();
          }
        })
        .catch(() => {
          this.areStudentsLoading = false;
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
    //fetch all students
    getStudents() {
      this.areStudentsLoading = true;
      studentRepository
        .getAllWithAcademicInformation()
        .then(({ data }) => {
          this.students = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.areStudentsLoading = false;
        });
    },

    /*If there's a code available when an "onChange" event takes
       place, the request will be made. otherwise, dont.*/
    get(code) {
      if (code != null) {
        this.getSelectedStudentDetails(code);
        //request functions
        this.getAllAcademicScoresSummary(code);
        this.getAllTechnicalScoresSummary(code);
      }
    },

    getSelectedStudentDetails(code) {
      this.selectedStudent = this.students.find((obj) => obj.code === code);

      // If the object is found, create a non-interactive copy
      if (this.selectedStudent) {
        return { ...this.selectedStudent }; // Creating a shallow copy
      } else {
        return null; // Object not found
      }
    },

    //Fetch a student's academic score summary
    async getAllAcademicScoresSummary(code) {
      try {
        this.evaluation.isLoadingAcademic = true;

        const res = await axios.get(
          `${this.getApiBaseURL}/subjects/academics/scores-average/code/${code}`
        );
        this.evaluation.data.academic = res.data;
        this.evaluation.isLoadingAcademic = false;
      } catch (e) {
        this.fireToast({
          icon: "error",
          title: "No ha sido posible cargar los datos desde el servidor",
        });
      }
    },

    //Fetch a student's technical score summary
    async getAllTechnicalScoresSummary(code) {
      try {
        this.evaluation.isLoadingTechnical = true;
        const res = await axios.get(
          `${this.getApiBaseURL}/subjects/technicals/scores-average/code/${code}`
        );
        this.evaluation.data.technical = res.data;
        this.evaluation.isLoadingTechnical = false;
      } catch (e) {
        this.fireToast({
          icon: "error",
          title: "No ha sido posible cargar los datos desde el servidor",
        });
      }
    },

    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    ...mapGetters(["userType", "currentUserPersonalInfo"]),
    getUserType() {
      const { user_type: userType } = this.userType;
      return userType;
    },
    getApiBaseURL() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.GE_STUDENTS_API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.GE_STUDENTS_DEV_API_URL;
      } else {
        baseURL = process.env.GE_STUDENTS_LOCAL_API_URL;
      }
      return baseURL;
    },
  },
};
</script>
