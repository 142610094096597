<template>
  <div class="ma-0 pa-0">
    <!-- begin::the component in 1/4 width -->
    <v-col cols="12" sm="4" class="pa-0" v-if="fluid">
      <div class="ma-0 pa-0" v-if="studentStatus == 1">
        <!-- begin::the component as a progress tool or has a replacement score -->
        <div class="ma-0 pa-0" v-if="replacementScore > 0 || isProgressTool">
          <v-row>
            <v-col cols="12" class="pt-0">
              <!-- begin::progress tool container -->
              <v-alert text class="ma-3 pa-1">
                <!-- begin::component title as a progress tool -->
                <div class="d-none" v-if="title.length < 1">
                  {{ title }}
                </div>
                <div
                  :class="`${color} ma-0 pa-0 text-center text-white text-body-1 rounded rounded-b-0`"
                  v-else
                >
                  {{ title }}
                </div>
                <!-- end::component title as a progress tool -->

                <!-- begin:: component body as a progress tool -->
                <v-row>
                  <!-- begin:: current score / accumulated advance -->
                  <v-col cols="6" class="pr-1">
                    <v-alert class="ma-0 px-0 rounded-t-0 py-2" text>
                      <p
                        class="pa-0 ma-0 text-body-1 text-center font-weight-bold"
                      >
                        {{ score }}
                      </p>
                    </v-alert>
                  </v-col>
                  <!-- end:: current score / accumulated advance -->

                  <v-divider
                    inset
                    vertical
                    style="height: 35px"
                    class="my-0 mt-3 mb-3 py-0"
                  ></v-divider>

                  <!-- begin:: Replacement score / total progress -->
                  <v-col cols="6" class="pl-1">
                    <v-alert class="ma-0 px-0 rounded-t-0 py-2" text>
                      <p
                        class="pa-0 ma-0 text-body-1 text-center font-weight-bold"
                      >
                        {{ replacementScore }}
                      </p>
                    </v-alert>
                  </v-col>
                  <!-- end:: Replacement score / total progress -->
                </v-row>
                <!-- end:: component body as a progress tool -->
              </v-alert>
              <!-- end::progress tool container -->
            </v-col>
          </v-row>
        </div>
        <!-- end::the component as a progress tool or has a replacement score -->
        <!-- begin::the component as a score tool-->
        <div class="ma-0 pa-0 mt-1" v-else>
          <v-row class="d-flex justify-center">
            <v-col cols="10" class="pb-5 pt-2">
              <v-alert text class="ma-0 pa-1">
                <!-- begin::component title as a score tool -->
                <div class="d-none" v-if="title.length < 1">
                  {{ title }}
                </div>
                <div
                  :class="`${color} ma-0 pa-0 text-center text-white text-body-1 rounded rounded-b-0`"
                  v-else
                >
                  {{ title }}
                </div>
                <!-- end::component title as a score tool -->

                <!-- begin:: current score-->
                <v-alert class="ma-0 px-0 rounded-t-0 py-2" text>
                  <p class="pa-0 ma-0 text-body-1 text-center font-weight-bold">
                    {{ score }}
                  </p>
                </v-alert>
                <!-- end:: current score-->
              </v-alert>
            </v-col>
          </v-row>
        </div>
        <!-- end::the component as a score tool-->
      </div>
      <!-- begin::If the user has pending debt -->
      <div v-else-if="studentStatus == 0">
        <v-row class="d-flex justify-center">
          <v-col cols="12" class="pb-5 pt-2">
            <v-menu open-on-hover left offset-x :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-alert text class="ma-0 pa-1" v-bind="attrs" v-on="on">
                  <v-alert color="warning" class="ma-0 rounded pa-3">
                    <v-icon> mdi-clock-alert-outline </v-icon>
                  </v-alert>
                </v-alert>
              </template>
              <!-- begin::debt alert -->
              <v-card class="pa-4" style="width: 300px !important">
                <p class="text-h6 ma-0 mb-2">Aviso</p>
                <p class="text-body-1 ma-0">
                  En nuestros registros se refleja mora en el pago.
                  <strong>
                    Si existe error favor comunicarse al 2234-6004 presentando
                    el comprobante de pago.</strong
                  >
                </p>
              </v-card>
              <!-- end::debt alert -->
            </v-menu>
          </v-col>
        </v-row>
      </div>
      <!-- end::If the user has pending debt -->
    </v-col>
    <!-- end::the component in 1/4 width -->

    <!-- begin::the component in full width -->
    <v-col cols="12" class="pa-0" v-if="notFluid">
      <!-- begin:: case when usar is not in debt -->
      <div class="ma-0 pa-0" v-if="studentStatus == 1">
        <!-- begin::in case there's a score after recuperation or the component is being used to show a percentaje of a total -->
        <div class="ma-0 pa-0" v-if="replacementScore > 0 || isProgressTool">
          <v-row>
            <v-col cols="12" class="pa-1">
              <div class="ma-0 pa-2 blue-grey lighten-5 rounded">
                <!-- begin::score or progress tool title (in case there´s a title) -->
                <div class="d-none" v-if="title.length < 1"></div>
                <div
                  :class="`${titleColor} ma-0 pa-0 text-center text-white text-body-1 rounded rounded-b-0`"
                  v-else
                >
                  {{ title }}
                </div>
                <!-- end::score or progress tool title (in case there´s a title) -->
                <!-- begin::score/progress content -->
                <v-row>
                  <v-col cols="6" class="pr-1">
                    <div
                      :class="`${getScoreColor(
                        subjectType,
                        score
                      )} ma-0 px-0 rounded py-3`"
                    >
                      <p
                        class="text-center text-h6 font-weight-medium ma-0 white--text"
                      >
                        {{ score }}
                      </p>
                    </div>
                  </v-col>
                  <v-divider inset vertical class="my-0 my-3 py-0"></v-divider>
                  <v-col cols="6" class="pl-1">
                    <div
                      :class="`${getScoreColor(
                        subjectType,
                        replacementScore
                      )} ma-0 px-0 rounded py-3`"
                    >
                      <p
                        class="text-center text-h6 font-weight-medium ma-0 white--text"
                      >
                        {{ replacementScore }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <!-- end::score/progress content -->
              </div>
            </v-col>
          </v-row>
        </div>
        <!-- end::in case there's a score after recuperation or the component is being used to show a percentaje of a total -->

        <!-- begin::Evaluation score -->
        <div class="ma-0 pa-0" v-else>
          <v-row class="d-flex justify-center align-center">
            <v-col cols="10">
              <div class="ma-0 pa-0">
                <!-- begin::score title (in case there´s a title) -->
                <div
                  :class="`${titleColor} ma-0 pa-0 text-center text-white text-body-1 rounded rounded text-center`"
                >
                  {{ title }}
                </div>
                <!-- end::score title (in case there´s a title) -->

                <!-- begin::score content -->
                <div
                  :class="`${getScoreColor(
                    subjectType,
                    score
                  )} ma-0 px-0 rounded py-3`"
                  v-if="true"
                >
                  <p
                    class="pa-0 ma-0 text-h6 text-center font-weight-medium text-white"
                  >
                    {{ score }}
                  </p>
                </div>
                <!-- end::score content -->

                <!-- begin::fallback when there isn't any score yet -->
                <div :class="`grey lighten-1 ma-0 px-0 rounded py-3`" v-else>
                  <v-menu
                    open-on-hover
                    left
                    offset-x
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <p
                        v-bind="attrs"
                        v-on="on"
                        class="pa-0 ma-0 text-h6 text-center font-weight-medium text-white"
                      >
                        NPI
                      </p>
                    </template>

                    <v-card class="pa-4" style="width: 300px !important">
                      <p class="text-h6 ma-0 mb-1">
                        Nota Pendiente de Ingresar
                      </p>
                      <p class="text-body-1 ma-0">
                        Aún se está evaluando o está siendo calificado.
                      </p>
                    </v-card>
                  </v-menu>
                </div>
                <!-- begin::fallback when there isn't any score yet -->
              </div>
            </v-col>
          </v-row>
        </div>
        <!-- end::Evaluation score -->
      </div>
      <!-- end:: case when usar is not in debt -->
      <!-- begin:: case when user is in debt -->
      <div v-else-if="studentStatus == 0">
        <v-row class="d-flex justify-center">
          <v-col cols="12" class="pb-5 pt-2">
            <v-menu open-on-hover left offset-x :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-alert text class="ma-0 pa-1" v-bind="attrs" v-on="on">
                  <v-alert
                    color="warning"
                    class="ma-0 rounded pa-3 d-flex justify-center"
                  >
                    <v-icon> mdi-clock-alert-outline </v-icon>
                  </v-alert>
                </v-alert>
              </template>

              <v-card class="pa-4" style="width: 300px !important">
                <p class="text-h6 ma-0 mb-1">Aviso</p>
                <p class="text-body-1 ma-0">
                  En nuestros registros se refleja mora en el pago.
                  <strong>
                    Si existe error favor comunicarse al 2234-6004 presentando
                    el comprobante de pago.</strong
                  >
                </p>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </div>
      <!-- end:: case when user is in debt -->
    </v-col>
    <!-- end::the component in full width -->

    <!-- begin::The score showed in stage -->
    <v-col cols="12" class="pa-0 ma-0" v-if="stageScore">
      <div class="ma-0 pa-0" v-if="studentStatus == 1">
        <!-- this will show if the student has entered and finished the score recuperation process; or, the component has been set to be a progress tool (it cannot be both at the same time)-->
        <div class="ma-0 pa-0" v-if="replacementScore > 0">
          <v-row class="ma-0 pa-0">
            <v-col cols="12" class="pa-0">
              <div class="pa-0">
                <!-- begin::score title (in case there´s a title) -->
                <div class="d-none" v-if="title.length < 1"></div>
                <div
                  :class="`${titleColor} ma-0 pa-0 text-center text-white text-body-1 rounded rounded-b-0`"
                  v-else
                >
                  {{ title }}
                </div>
                <!-- end::score title (in case there´s a title) -->

                <!-- begin::score and replacement score content -->
                <v-row
                  class="white rounded ma-1 py-0 px-2 d-flex justify-center align-center"
                >
                  <!-- begin::stage score -->
                  <v-col
                    :class="`${getScoreColor(
                      subjectType,
                      score
                    )} rounded d-flex justify-center align-center py-2`"
                  >
                    <v-menu
                      open-on-hover
                      left
                      offset-x
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <p
                          class="text-center text-h6 font-weight-medium ma-0 white--text"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ score }}
                        </p>
                      </template>

                      <v-card class="pa-4" style="width: 300px !important">
                        <p class="text-h6 ma-0 mb-1">Nota anterior</p>
                        <p class="text-body-1 ma-0">
                          Esta es la nota de la etapa,
                          <strong>previa al proceso de recuperación</strong> .
                        </p>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <!-- end::stage score -->
                  <v-divider
                    inset
                    vertical
                    style="height: 35px"
                    class="my-0 my-3 mx-1 py-0"
                  ></v-divider>
                  <!-- begin::stage replacement score -->
                  <v-col
                    :class="`${getScoreColor(
                      subjectType,
                      replacementScore
                    )} rounded d-flex justify-center align-center py-2`"
                  >
                    <v-menu
                      open-on-hover
                      left
                      offset-x
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <p
                          class="text-center text-h6 font-weight-medium ma-0 white--text"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ replacementScore }}
                        </p>
                      </template>

                      <v-card class="pa-4" style="width: 300px !important">
                        <p class="text-h6 ma-0 mb-1">Nota actual</p>
                        <p class="text-body-1 ma-0">
                          Esta es la nota de la etapa,
                          <strong>después del proceso de recuperación</strong>.
                        </p>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <!-- end::stage replacement score -->
                </v-row>
                <!-- end::score and replacement score content -->
              </div>
            </v-col>
          </v-row>
        </div>
        <!-- begin:: case when there's no recuperation score -->
        <div class="ma-0 pa-0" v-else>
          <v-row
            class="ma-1 pa-2 white rounded d-flex justify-center justify-sm-end"
          >
            <!-- begin:: score content -->
            <v-col
              v-if="true"
              :class="`${getScoreColor(
                subjectType,
                score
              )} rounded d-flex justify-center align-center py-2`"
            >
              <v-menu
                open-on-hover
                left
                offset-x
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <p
                    class="text-center text-h6 font-weight-medium ma-0 white--text"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ score }}
                  </p>
                </template>

                <v-card class="pa-4" style="width: 300px !important">
                  <p class="text-h6 ma-0 mb-1">
                    {{ subjectType == 1 ? "Nivel de logro" : "Nota de etapa" }}
                  </p>
                  <p class="text-body-1 ma-0">
                    {{
                      subjectType == 1
                        ? "Nivel de logro obtenido en el módulo."
                        : "Promedio de todas notas obtenidas en los perfiles de una etapa."
                    }}
                  </p>
                </v-card>
              </v-menu>
            </v-col>
            <!-- end:: score content -->

            <!-- begin::fallback when there isn't any score yet -->
            <v-col
              v-else
              class="grey lighten-1 rounded d-flex justify-center align-center py-4`"
            >
              <v-menu
                open-on-hover
                left
                offset-x
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <p
                    class="text-center text-body-1 font-weight-normal ma-0 white--text"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ subjectType == 1 ? "Sin comenzar" : "No disponible" }}
                  </p>
                </template>

                <v-card class="pa-4" style="width: 300px !important">
                  <p class="text-h6 ma-0 mb-1">
                    {{
                      subjectType == 1
                        ? "Módulo no empezado"
                        : "Promedio de Etapa No Disponible"
                    }}
                  </p>
                  <p class="text-body-1 ma-0">
                    {{
                      subjectType == 1
                        ? "Este módulo aún no se ha comenzado a trabajar."
                        : "Aún no se ha comenzado a evaluar esta etapa."
                    }}
                  </p>
                </v-card>
              </v-menu>
            </v-col>
            <!-- end::fallback when there isn't any score yet -->
          </v-row>
        </div>
        <!-- end:: case when there's no recuperation score -->
      </div>
      <!-- begin:: case when user is in debt -->
      <div v-else-if="studentStatus == 0">
        <v-row class="d-flex justify-center">
          <v-col cols="12" class="pb-5 pt-2">
            <v-menu open-on-hover left offset-x :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-alert text class="ma-0 pa-1" v-bind="attrs" v-on="on">
                  <v-alert
                    color="warning"
                    class="ma-0 rounded pa-3 d-flex justify-center"
                  >
                    <v-icon> mdi-clock-alert-outline </v-icon>
                  </v-alert>
                </v-alert>
              </template>

              <v-card class="pa-4" style="width: 300px !important">
                <p class="text-h6 ma-0 mb-1">Aviso</p>
                <p class="text-body-1 ma-0">
                  En nuestros registros se refleja mora en el pago.
                  <strong>
                    Si existe error favor comunicarse al 2234-6004 presentando
                    el comprobante de pago.</strong
                  >
                </p>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </div>
      <!-- end:: case when user is in debt -->
    </v-col>
    <!-- end::The score showed in stage -->

    <!-- begin::Score showed in summary -->
    <div v-if="summaryScore">
      <!-- begin:: case when user is not in debt -->
      <div v-if="studentStatus == 1">
        <!-- begin::In case there's a recuperation score -->
        <div
          class="ma-0 pa-0 d-flex justify-center align-center"
          v-if="replacementScore > 0"
        >
          <p :class="`ma-0 text-center text-body-1 font-weight-bold`">
            <span :class="`${getScoreColor(subjectType, score)}--text`">{{
              score
            }}</span>
            →
            <span
              :class="`${getScoreColor(subjectType, replacementScore)}--text`"
              >{{ replacementScore }}</span
            >
          </p>
        </div>
        <!-- end::In case there's a recuperation score -->

        <!-- begin::summary score content -->
        <div v-else>
          <!-- begin::summary score -->
          <v-menu
            v-if="true"
            open-on-hover
            bottom
            nudge-bottom="5px"
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <p
                v-bind="attrs"
                v-on="on"
                :class="`ma-0 text-center text-h6 font-weight-bold ${getScoreColor(
                  subjectType,
                  score
                )}--text`"
              >
                {{ score }}
              </p>
            </template>

            <v-card
              class="pa-4"
              :style="`${
                subjectType == 1 ? 'width:200px' : 'width:150px'
              } !important; cursor:pointer`"
            >
              <p class="text-body-1 font-weight-medium text-center ma-0">
                {{
                  subjectType == 1 ? "Nivel de logro obtenido" : "Nota obtenida"
                }}
              </p>
            </v-card>
          </v-menu>
          <!-- end::summary score -->
          <!-- begin::Fallback when there isn't a score yet -->
          <v-menu
            v-else
            open-on-hover
            top
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <p
                v-bind="attrs"
                v-on="on"
                :class="`ma-0 text-center text-h6 font-weight-bold grey--text`"
              >
                NPI
              </p>
            </template>

            <v-card class="pa-4" style="width: 300px !important">
              <p class="text-h6 ma-0 mb-1">
                {{
                  subjectType == 1
                    ? "Nivel de logro pediente de ingresar"
                    : "Nota Pendiente de Ingresar"
                }}
              </p>
              <p class="text-body-1 ma-0">
                Aún no se ha comenzado a evaluar o está siendo calificada.
              </p>
            </v-card>
          </v-menu>
          <!-- end::Fallback when there isn't a score yet -->
        </div>
        <!-- end::summary score content -->
      </div>
      <!-- end:: case when user is not in debt -->
      <!-- begin:: case when user is in debt -->
      <div v-if="studentStatus == 0">
        <v-icon class="my-1" color="orange"> mdi-clock-alert-outline </v-icon>
      </div>
      <!-- end:: case when user is in debt -->
    </div>
    <!-- end::Score showed in summary -->

    <!-- begin::Score showed in caterory -->
    <div v-if="categoryScore">
      <!-- begin:: case when user is not in debt -->
      <div v-if="studentStatus == 1">
        <!-- begin::In case there's a recuperation score -->
        <div
          class="ma-0 pa-0 d-flex justify-center align-center"
          v-if="replacementScore > 0"
        >
          <v-menu
            v-if="true"
            open-on-click
            bottom
            nudge-bottom="5px"
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                class="elevation-2 white pa-2 rounded"
                v-bind="attrs"
                v-on="on"
              >
                <p :class="`ma-0 text-body-1 font-weight-bold blue-grey--text`">
                  <span>{{ subjectType == 1 ? "N.L." : "Nota" }}</span> antes
                  del proceso de recuperación:
                  <span :class="`${getScoreColor(subjectType, score)}--text`">{{
                    score
                  }}</span>
                  <v-divider class="my-1"></v-divider>
                  <span>{{ subjectType == 1 ? "N.L." : "Nota" }}</span> después
                  del proceso de recuperación:
                  <span
                    :class="`${getScoreColor(
                      subjectType,
                      replacementScore
                    )}--text`"
                    >{{ replacementScore }}</span
                  >
                </p>
              </div>
            </template>

            <v-card
              class="pa-4"
              :style="`${
                subjectType == 1 ? 'width:250px' : 'width:250px'
              } !important; cursor:pointer`"
            >
              <p class="text-body-1 font-weight-medium text-center ma-0">
                {{
                  subjectType == 1
                    ? "Nivel de logro obtenido, antes y después del proceso de recuperación"
                    : "Nota obtenida, antes y después del proceso de recuperación"
                }}
              </p>
            </v-card>
          </v-menu>

          <v-menu
            v-if="score < 1"
            open-on-click
            top
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip class="white" label>
                <p
                  v-bind="attrs"
                  v-on="on"
                  :class="`ma-0 text-center text-h6 font-weight-bold grey--text`"
                >
                  <span>{{ subjectType == 1 ? "N.L." : "Nota" }}</span> de fase:
                  NPI
                </p>
              </v-chip>
            </template>

            <v-card class="pa-4" style="width: 300px !important">
              <p class="text-h6 ma-0 mb-1">
                {{
                  subjectType == 1
                    ? "Nivel de logro (N.L.) pediente de ingresar"
                    : "Nota Pendiente de Ingresar"
                }}
              </p>
              <p class="text-body-1 ma-0">
                Aún no se ha comenzado a evaluar o está siendo calificada.
              </p>
            </v-card>
          </v-menu>
        </div>
        <!-- end::In case there's a recuperation score -->

        <!-- begin::summary score content -->
        <div v-else>
          <!-- begin::summary score -->
          <v-menu
            v-if="true"
            open-on-click
            bottom
            nudge-bottom="5px"
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="white"
                class="elevation-2"
                label
                v-bind="attrs"
                v-on="on"
              >
                <p
                  :class="`ma-0 text-center text-h6 font-weight-medium blue-grey--text`"
                >
                  <span>{{ subjectType == 1 ? "N.L." : "Nota" }}</span> de fase:
                  <span
                    :class="`${getScoreColor(
                      subjectType,
                      score
                    )}--text font-weight-medium`"
                    >{{ score }}</span
                  >
                </p>
              </v-chip>
            </template>

            <v-card
              class="pa-4"
              :style="`${
                subjectType == 1 ? 'width:250px' : 'width:250px'
              } !important; cursor:pointer`"
            >
              <p class="text-body-1 font-weight-medium text-center ma-0">
                {{
                  subjectType == 1
                    ? "Nivel de logro (N.L.) obtenido"
                    : "Nota obtenida"
                }}
              </p>
            </v-card>
          </v-menu>
          <!-- end::summary score -->
          <!-- begin::Fallback when there isn't a score yet -->

          <v-menu
            v-else
            open-on-click
            top
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip class="white" label>
                <p
                  v-bind="attrs"
                  v-on="on"
                  :class="`ma-0 text-center text-h6 font-weight-bold grey--text`"
                >
                  <span>{{ subjectType == 1 ? "N.L." : "Nota" }}</span> de fase:
                  NPI
                </p>
              </v-chip>
            </template>

            <v-card class="pa-4" style="width: 320px !important">
              <p class="text-h6 ma-0 mb-1">
                {{
                  subjectType == 1
                    ? "Nivel de logro (N.L.) pediente de ingresar"
                    : "Nota Pendiente de Ingresar"
                }}
              </p>
              <p class="text-body-1 ma-0">
                Aún no se ha comenzado a evaluar o está siendo calificada.
              </p>
            </v-card>
          </v-menu>
          <!-- end::Fallback when there isn't a score yet -->
        </div>
        <!-- end::summary score content -->
      </div>
      <!-- end:: case when user is not in debt -->
      <!-- begin:: case when user is in debt -->
      <div v-if="studentStatus == 0">
        <v-icon class="my-1" color="orange"> mdi-clock-alert-outline </v-icon>
      </div>
      <!-- end:: case when user is in debt -->
    </div>
    <!-- end::Score showed in caterory -->
  </div>
</template>

<script>
export default {
  name: "ScoreViewer",
  props: {
    true: [Boolean],
    title: {
      type: String,
      default: "",
    },
    score: {
      type: [Number, String],
    },
    replacementScore: {
      type: [Number, String],
    },
    isProgressTool: {
      type: Boolean,
      default: false,
    },
    titleColor: {
      type: String,
    },
    fluid: {
      type: Boolean,
    },
    notFluid: {
      type: Boolean,
    },
    studentStatus: {
      type: Number,
    },
    stageScore: {
      type: Boolean,
    },
    subjectType: {
      type: Number,
    },
    summaryScore: {
      type: Boolean,
    },
    categoryScore: {
      type: Boolean,
    },
  },
  methods: {
    /*Set a color depending on the subject(academic/module)
     type and the score (from 1 to 10)*/
    getScoreColor(subType, score) {
      if (subType == 1) {
        if (score < 3.5) {
          return "red";
        } else {
          return "success";
        }
      } else if (subType == 2) {
        if (score < 5.5) {
          return "red";
        } else {
          if (score > 5.49 && score < 6.0) {
            return "orange";
          } else {
            if (score > 5.9) {
              return "success";
            } else {
              return "grey";
            }
          }
        }
      }
    },
  },
};
</script>
