<template>
  <v-row class="d-flex justify-start">
    <v-col>
      <!-- begin::Information card -->
      <v-row>
        <v-col
          cols="12"
          lg="6"
          xl="4"
          v-for="(item, index) in evaluation"
          :key="index"
        >
          <div class="pa-0 ma-0">
            <!-- begin::Subject/Module name -->
            <v-row class="ma-0 pa-0 mb-n3">
              <v-col
                cols="12"
                :sm="`${
                  item.subject.subject_evaluation_scale.subject_type_id == 1
                    ? '12'
                    : '10'
                }`"
                :md="`${
                  item.subject.subject_evaluation_scale.subject_type_id == 1
                    ? '10'
                    : '8'
                }`"
                :xl="`${
                  item.subject.subject_evaluation_scale.subject_type_id == 1
                    ? '10'
                    : '8'
                }`"
                :class="`${colors[item.subject.id % 12]}
                       rounded rounded-b-0`"
              >
                <h5
                  class="pa-0 mb-0 ml-1 white--text text-center text-md-left font-weight-medium subtitle tab"
                >
                  {{ item.subject.name }}
                </h5>
              </v-col>
            </v-row>
            <!-- end::Subject/Module name -->

            <v-row>
              <v-col cols="12" class="pt-0">
                <v-container class="blue-grey lighten-4 rounded rounded-tl-0">
                  <!-- begin:: Annual calculations -->
                  <v-card
                    v-if="
                      item.subject.subject_evaluation_scale.subject_type_id == 2
                    "
                    :class="`py-4 px-4 mb-3`"
                  >
                    <!-- begin:: global scores -->
                    <v-row class="">
                      <v-col>
                        <v-sheet v-if="true">
                          <v-row>
                            <v-col cols="12">
                              <div class="d-flex justify-start">
                                <div>
                                  <v-avatar color="blue lighten-5" size="60">
                                    <v-icon color="blue">
                                      mdi-book-outline</v-icon
                                    >
                                  </v-avatar>
                                </div>
                                <div class="ml-2">
                                  <p class="text-body-1 font-weight-bold mb-0">
                                    Acumulado global:
                                  </p>
                                  <p
                                    class="text-body-1 font-weight-medium mb-0"
                                  >
                                    <v-chip
                                      label
                                      :class="`${getScoreColor(
                                        item.subject.subject_evaluation_scale
                                          .subject_type_id,
                                        item.subject.final_score
                                      )} mb-1`"
                                      small
                                    >
                                      <span
                                        class="white--text font-weight-bold"
                                      >
                                        {{ item.subject.final_score }}
                                      </span>
                                    </v-chip>
                                  </p>
                                </div>
                              </div>
                            </v-col>

                            <v-col
                              cols="12"
                              v-if="item.subject.extraordinary_final_score > 0"
                            >
                              <div class="d-flex justify-start">
                                <div>
                                  <v-avatar color="indigo lighten-5" size="60">
                                    <v-icon color="indigo"
                                      >mdi-book-plus-outline</v-icon
                                    >
                                  </v-avatar>
                                </div>
                                <div class="ml-2">
                                  <p class="text-body-1 font-weight-bold mb-0">
                                    Acumulado global después de recuperación
                                    extraordinaria:
                                  </p>
                                  <p
                                    class="text-body-1 font-weight-medium mb-0"
                                  >
                                    <v-chip
                                      label
                                      :class="`${getScoreColor(
                                        item.subject.subject_evaluation_scale
                                          .subject_type_id,
                                        item.subject.extraordinary_final_score
                                      )} mb-1`"
                                      small
                                    >
                                      <span class="white--text font-weight-bold"
                                        >{{
                                          item.subject.extraordinary_final_score
                                        }}
                                      </span>
                                    </v-chip>
                                  </p>
                                </div>
                              </div>
                            </v-col>
                          </v-row>

                          <v-divider
                            v-if="item.subject.avanzo_score > 0"
                          ></v-divider>

                          <v-row v-if="item.subject.avanzo_score > 0">
                            <v-col cols="12">
                              <div class="d-flex justify-start">
                                <div>
                                  <v-avatar color="cyan lighten-5" size="60">
                                    <v-icon color="cyan">
                                      mdi-school-outline</v-icon
                                    >
                                  </v-avatar>
                                </div>
                                <div class="ml-2">
                                  <p class="text-body-1 font-weight-bold mb-0">
                                    Nota obtenida en prueba Avanzo
                                  </p>
                                  <p
                                    class="text-body-1 font-weight-medium mb-0"
                                  >
                                    <v-chip
                                      label
                                      :class="`${getScoreColor(
                                        item.subject.subject_evaluation_scale
                                          .subject_type_id,
                                        item.subject.avanzo_score
                                      )} mb-1`"
                                      small
                                    >
                                      <span
                                        class="white--text font-weight-bold"
                                        >{{ item.subject.avanzo_score }}</span
                                      >
                                    </v-chip>
                                  </p>
                                </div>
                              </div>
                            </v-col>

                            <v-col
                              cols="12"
                              v-if="
                                item.subject.final_score_with_avanzo_score > 0
                              "
                            >
                              <div class="d-flex justify-start">
                                <div>
                                  <v-avatar color="teal lighten-5" size="60">
                                    <v-icon color="teal">
                                      mdi-school-outline</v-icon
                                    >
                                  </v-avatar>
                                </div>
                                <div class="ml-2">
                                  <p class="text-body-1 font-weight-bold mb-0">
                                    Nota global promediada con nota de prueba
                                    Avanzo
                                  </p>
                                  <p
                                    class="text-body-1 font-weight-medium mb-0"
                                  >
                                    <v-chip
                                      label
                                      :class="`${getScoreColor(
                                        item.subject.subject_evaluation_scale
                                          .subject_type_id,
                                        item.subject
                                          .final_score_with_avanzo_score
                                      )} mb-1`"
                                      small
                                    >
                                      <span
                                        :class="`white--text font-weight-bold`"
                                      >
                                        {{
                                          item.subject
                                            .final_score_with_avanzo_score
                                        }}
                                      </span>
                                    </v-chip>
                                  </p>
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                        </v-sheet>
                        <v-sheet v-else>
                          <v-row
                            :class="`d-flex flex-column flex-md-row mr-6 my-1 ml-1 rounded-lg `"
                          >
                            <v-col class="pa-0 ma-0" cols="12">
                              <div
                                class="d-flex justify-start align-center mr-md-3"
                              >
                                <div>
                                  <v-avatar color="orange lighten-5" size="60">
                                    <v-icon large color="orange"
                                      >mdi-clock-alert-outline</v-icon
                                    >
                                  </v-avatar>
                                </div>
                                <div class="ml-3">
                                  <p
                                    class="text-subtitle-1 font-weight-medium mb-0"
                                  >
                                    En nuestros registros se refleja mora en el
                                    pago.
                                  </p>
                                  <p
                                    class="text-body-1 font-weight-normal mb-0"
                                  >
                                    Si existe error favor comunicarse al
                                    2234-6030 ó al correo
                                    contabilidad@ricaldone.edu.sv y anexando un
                                    comprobante de pago válido.
                                  </p>
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                        </v-sheet>
                      </v-col>
                    </v-row>
                    <!-- end:: global scores -->
                  </v-card>
                  <v-card v-else :class="`py-4 px-4 mb-3`">
                    <!-- begin:: global scores -->
                    <v-row class="">
                      <v-col>
                        <v-sheet v-if="true">
                          <v-row>
                            <v-col cols="12">
                              <div class="d-flex justify-start">
                                <div>
                                  <v-avatar color="blue lighten-5" size="60">
                                    <v-icon color="blue">
                                      mdi-book-outline</v-icon
                                    >
                                  </v-avatar>
                                </div>
                                <div class="ml-2">
                                  <p class="text-body-1 font-weight-bold mb-0">
                                    Acumulado global:
                                  </p>
                                  <p
                                    class="text-body-1 font-weight-medium mb-0"
                                  >
                                    <v-chip
                                      label
                                      :class="`${getScoreColor(
                                        item.subject.subject_evaluation_scale
                                          .subject_type_id,
                                        item.subject.final_score
                                      )} mb-1`"
                                      small
                                    >
                                      <span
                                        class="white--text font-weight-bold"
                                      >
                                        {{ item.subject.final_score }}
                                      </span>
                                    </v-chip>
                                  </p>
                                </div>
                              </div>
                            </v-col>

                            <v-col
                              cols="12"
                              v-if="item.subject.final_recovery_score > 0"
                            >
                              <div class="d-flex justify-start">
                                <div>
                                  <v-avatar color="indigo lighten-5" size="60">
                                    <v-icon color="indigo"
                                      >mdi-book-outline</v-icon
                                    >
                                  </v-avatar>
                                </div>
                                <div class="ml-2">
                                  <p class="text-body-1 font-weight-bold mb-0">
                                    Acumulado global después de recuperación
                                    ordinaria:
                                  </p>
                                  <p
                                    class="text-body-1 font-weight-medium mb-0"
                                  >
                                    <v-chip
                                      label
                                      :class="`${getScoreColor(
                                        item.subject.subject_evaluation_scale
                                          .subject_type_id,
                                        item.subject.final_recovery_score
                                      )} mb-1`"
                                      small
                                    >
                                      <span class="white--text font-weight-bold"
                                        >{{ item.subject.final_recovery_score }}
                                      </span>
                                    </v-chip>
                                  </p>
                                </div>
                              </div>
                            </v-col>

                            <v-col
                              cols="12"
                              v-if="item.subject.extraordinary_final_score > 0"
                            >
                              <div class="d-flex justify-start">
                                <div>
                                  <v-avatar
                                    color="deep-purple lighten-5"
                                    size="60"
                                  >
                                    <v-icon color="deep-purple"
                                      >mdi-book-outline</v-icon
                                    >
                                  </v-avatar>
                                </div>
                                <div class="ml-2">
                                  <p class="text-body-1 font-weight-bold mb-0">
                                    Acumulado global después de recuperación
                                    extraordinaria:
                                  </p>
                                  <p
                                    class="text-body-1 font-weight-medium mb-0"
                                  >
                                    <v-chip
                                      label
                                      :class="`${getScoreColor(
                                        item.subject.subject_evaluation_scale
                                          .subject_type_id,
                                        item.subject.extraordinary_final_score
                                      )} mb-1`"
                                      small
                                    >
                                      <span class="white--text font-weight-bold"
                                        >{{
                                          item.subject.extraordinary_final_score
                                        }}
                                      </span>
                                    </v-chip>
                                  </p>
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                        </v-sheet>
                        <v-sheet v-else>
                          <v-row
                            :class="`d-flex flex-column flex-md-row mr-6 my-1 ml-1 rounded-lg `"
                          >
                            <v-col class="pa-0 ma-0" cols="12">
                              <div
                                class="d-flex justify-start align-center mr-md-3"
                              >
                                <div>
                                  <v-avatar color="orange lighten-5" size="60">
                                    <v-icon large color="orange"
                                      >mdi-clock-alert-outline</v-icon
                                    >
                                  </v-avatar>
                                </div>
                                <div class="ml-3">
                                  <p
                                    class="text-subtitle-1 font-weight-medium mb-0"
                                  >
                                    En nuestros registros se refleja mora en el
                                    pago.
                                  </p>
                                  <p
                                    class="text-body-1 font-weight-normal mb-0"
                                  >
                                    Si existe error favor comunicarse al
                                    2234-6030 ó al correo
                                    contabilidad@ricaldone.edu.sv y anexando un
                                    comprobante de pago válido.
                                  </p>
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                        </v-sheet>
                      </v-col>
                    </v-row>
                    <!-- end:: global scores -->
                  </v-card>
                  <!-- end:: annual calculations chips  -->

                  <!-- begin:: Evaluations separated by stages -->
                  <v-card class="px-2 py-1 grey lighten-5">
                    <v-row>
                      <v-col cols="12 pb-0 ">
                        <p
                          class="ma-0 pa-0 ml-2 py-2 text-center text-md-left text-body-1"
                        >
                          Detalle de notas por cada
                          <strong>etapa y fase</strong> de la asignatura:
                        </p>
                      </v-col>
                    </v-row>
                    <!-- begin::Collapsible stages container  -->
                    <v-row class="pb-2 pt-0">
                      <v-col v-if="item.subject.score_stages.length > 0">
                        <!-- begin:: Stages -->
                        <v-expansion-panels focusable>
                          <v-expansion-panel
                            v-for="(stage, stageIndex) in item.subject
                              .score_stages"
                            :key="stageIndex"
                          >
                            <v-expansion-panel-header
                              class="text-body-1 font-weight-medium py-3 px-2"
                              ><div>
                                <v-row
                                  v-if="true"
                                  :class="`d-flex flex-column flex-md-row mr-6 my-1 ml-1 rounded-lg `"
                                >
                                  <!-- begin:: Stage name and weight -->
                                  <v-col
                                    class="d-flex px-0 py-0 mb-2"
                                    cols="12"
                                  >
                                    <p
                                      class="pa-0 ma-0 text-h6 font-weight-bold"
                                    >
                                      {{ stage.stage }} ({{ stage.percent }}%)
                                    </p>
                                  </v-col>
                                  <!-- end:: Stage name and weight -->

                                  <v-col class="pa-0 ma-0" cols="12">
                                    <div
                                      class="d-flex justify-start align-center mr-md-3"
                                    >
                                      <div>
                                        <v-avatar
                                          color="blue lighten-5"
                                          size="60"
                                        >
                                          <v-icon color="blue"
                                            >mdi-tag-text-outline</v-icon
                                          >
                                        </v-avatar>
                                      </div>
                                      <!-- -------------------- -->
                                      <div v-if="true" class="ml-3">
                                        <p
                                          class="text-subtitle-1 font-weight-medium mb-0"
                                        >
                                          Nota final de
                                          {{
                                            stageType(
                                              item.subject
                                                .subject_evaluation_scale
                                                .subject_type_id
                                            )
                                          }}
                                        </p>
                                        <p
                                          class="text-body-1 font-weight-medium mb-0"
                                        >
                                          <v-chip
                                            label
                                            :class="`${getScoreColor(
                                              item.subject
                                                .subject_evaluation_scale
                                                .subject_type_id,
                                              stage.final_score
                                            )} mt-1`"
                                          >
                                            <span
                                              class="white--text font-weight-bold"
                                            >
                                              {{ stage.final_score }}
                                            </span>
                                          </v-chip>
                                        </p>
                                      </div>

                                      <v-menu
                                        v-else
                                        open-on-hover
                                        left
                                        offset-x
                                        :close-on-content-click="false"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <div
                                            class="ma-0 pa-0 ml-3 d-flex flex-column"
                                          >
                                            <p
                                              class="text-subtitle-1 font-weight-medium mb-0"
                                            >
                                              {{
                                                stageType(
                                                  item.subject
                                                    .subject_evaluation_scale
                                                    .subject_type_id
                                                ) !== "módulo"
                                                  ? "Nota"
                                                  : "Nivel de logro"
                                              }}
                                              pendiente de ingresar
                                            </p>
                                            <p
                                              v-bind="attrs"
                                              v-on="on"
                                              class="text-body-1 font-weight-medium mb-0"
                                            >
                                              <v-chip
                                                label
                                                :class="`grey lighten-1 mt-1`"
                                              >
                                                <span
                                                  class="white--text font-weight-bold"
                                                >
                                                  N.P.I
                                                </span>
                                              </v-chip>
                                            </p>
                                          </div>
                                        </template>

                                        <v-card
                                          class="pa-4"
                                          style="width: 300px !important"
                                        >
                                          <p class="text-h6 ma-0 mb-1">
                                            {{
                                              item.subject
                                                .subject_evaluation_scale
                                                .subject_type_id == 1
                                                ? "Módulo no empezado"
                                                : `Promedio de ${stageType(
                                                    item.subject
                                                      .subject_evaluation_scale
                                                      .subject_type_id
                                                  )} no disponible`
                                            }}
                                          </p>
                                          <p class="text-body-1 ma-0">
                                            {{
                                              item.subject
                                                .subject_evaluation_scale
                                                .subject_type_id == 1
                                                ? "Este módulo aún no se ha comenzado a trabajar o evaluar"
                                                : `Aún no se ha comenzado a evaluar este ${stageType(
                                                    item.subject
                                                      .subject_evaluation_scale
                                                      .subject_type_id
                                                  )}`
                                            }}
                                          </p>
                                        </v-card>
                                      </v-menu>
                                    </div>
                                  </v-col>
                                  <!-- <v-divider
                                    vertical
                                    class="d-none d-md-block ml-n1 mr-3"
                                    v-if="stage.recovery_score > 0"
                                  ></v-divider> -->
                                  <v-divider v-if="stage.recovery_score > 0">
                                  </v-divider>
                                  <v-col
                                    v-if="stage.recovery_score > 0"
                                    class="pa-0 ma-0 mt-md-3"
                                    cols="12"
                                  >
                                    <div
                                      class="d-flex justify-start align-center mr-md-3"
                                    >
                                      <div>
                                        <v-avatar
                                          color="deep-orange lighten-5"
                                          size="60"
                                        >
                                          <v-icon color="deep-orange"
                                            >mdi-tag-plus-outline</v-icon
                                          >
                                        </v-avatar>
                                      </div>
                                      <div class="ml-3">
                                        <p
                                          class="text-subtitle-1 font-weight-medium mb-0"
                                        >
                                          {{
                                            stageType(
                                              item.subject
                                                .subject_evaluation_scale
                                                .subject_type_id
                                            ) !== "módulo"
                                              ? "Nota"
                                              : "Nivel de logro"
                                          }}
                                          de
                                          {{
                                            stageType(
                                              item.subject
                                                .subject_evaluation_scale
                                                .subject_type_id
                                            )
                                          }}
                                          con nota de recuperación ordinaria
                                        </p>
                                        <p
                                          class="text-body-1 font-weight-medium mb-0"
                                        >
                                          <v-chip
                                            label
                                            :class="`${getScoreColor(
                                              item.subject
                                                .subject_evaluation_scale
                                                .subject_type_id,
                                              stage.recovery_score
                                            )} mt-1`"
                                          >
                                            <span
                                              class="white--text font-weight-bold"
                                            >
                                              {{ stage.recovery_score }}
                                            </span>
                                          </v-chip>
                                        </p>
                                      </div>
                                    </div>
                                  </v-col>
                                </v-row>

                                <v-row
                                  v-else
                                  :class="`d-flex flex-column flex-md-row mr-6 my-1 ml-1 rounded-lg `"
                                >
                                  <!-- begin:: Stage name and weight -->
                                  <v-col
                                    class="d-flex px-0 py-0 mb-2"
                                    cols="12"
                                  >
                                    <p
                                      class="pa-0 ma-0 text-h6 font-weight-bold"
                                    >
                                      {{ stage.stage }} ({{ stage.percent }}%)
                                    </p>
                                  </v-col>
                                  <!-- end:: Stage name and weight -->

                                  <v-col class="pa-0 ma-0" cols="12">
                                    <div
                                      class="d-flex justify-start align-center mr-md-3"
                                    >
                                      <div>
                                        <v-avatar
                                          color="orange lighten-5"
                                          size="60"
                                        >
                                          <v-icon large color="orange"
                                            >mdi-clock-alert-outline</v-icon
                                          >
                                        </v-avatar>
                                      </div>
                                      <div class="ml-3">
                                        <p
                                          class="text-subtitle-1 font-weight-medium mb-0"
                                        >
                                          En nuestros registros se refleja mora
                                          en el pago.
                                        </p>
                                        <p
                                          class="text-body-1 font-weight-normal mb-0"
                                        >
                                          Si existe error favor comunicarse al
                                          2234-6030 ó al correo
                                          contabilidad@ricaldone.edu.sv y
                                          anexando un comprobante de pago
                                          válido.
                                        </p>
                                      </div>
                                    </div>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="pt-3">
                              <v-container
                                v-for="(fase, faseIndex) in stage.categories"
                                :key="faseIndex"
                                class="ma-0 pa-0"
                              >
                                <!-- begin::categories title -->
                                <v-banner
                                  class="my-3 pa-0"
                                  color="secondary"
                                  elevation="5"
                                  :icon="`mdi-numeric-${faseIndex + 1}-circle`"
                                  outlined
                                  rounded
                                  single-line
                                >
                                  <p
                                    class="ma-0 text-sm-left text-body-1 font-weight-medium banner-title"
                                  >
                                    {{ fase.activity_category }}
                                    <br class="d-sm-none" />
                                    ({{ fase.percent }}%)
                                  </p>
                                </v-banner>
                                <!-- end::categories title -->
                                <v-row
                                  class="d-flex justify-center flex-column"
                                >
                                  <ScoreViewer
                                    class="mx-auto mt-3"
                                    :isUpdated="true"
                                    :score="fase.final_score"
                                    :replacementScore="fase.recovery_score"
                                    :categoryScore="true"
                                    :studentStatus="1"
                                    :subjectType="
                                      item.subject.subject_evaluation_scale
                                        .subject_type_id
                                    "
                                  ></ScoreViewer>
                                  <v-col
                                    class="px-0 px-sm-4"
                                    v-if="fase.evaluations.length > 0"
                                  >
                                    <!-- for each evaluation and its activities -->
                                    <v-banner
                                      outlined
                                      rounded
                                      class="pa-0 mb-2"
                                      v-for="(
                                        evaluation, evaluationIndex
                                      ) in fase.evaluations"
                                      :key="evaluationIndex"
                                    >
                                      <v-row
                                        class="d-flex justify-center align-center"
                                      >
                                        <!-- begin::Evaluation name -->
                                        <v-col class="px-0 py-1 mt-2" cols="8">
                                          <v-menu
                                            open-on-click
                                            bottom
                                            nudge-bottom="5px"
                                            offset-y
                                            :close-on-content-click="false"
                                            content-class="elevation-0"
                                          >
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <p
                                                v-bind="attrs"
                                                v-on="on"
                                                class="text-center text-sm-left ml-3 ma-0 pl-sm-2 text-body-1 font-weight-bold"
                                              >
                                                {{ evaluation.activity_type }}
                                                ({{
                                                  evaluation.percent_formatted
                                                }})
                                              </p>
                                            </template>

                                            <v-card
                                              class="pa-2 ma-0 elevation-20"
                                              style="
                                                width: 280px !important;
                                                border: 1px solid gray;
                                              "
                                            >
                                              <p
                                                class="text-body-2 ma-0 font-weight-medium"
                                              >
                                                Inicia el
                                                <span
                                                  class="font-weight-bold"
                                                  >{{
                                                    evaluation.start_date_formatted
                                                  }}</span
                                                >
                                                <br />
                                                y finaliza el
                                                <span class="font-weight-bold"
                                                  >{{
                                                    evaluation.end_date_formatted
                                                  }}
                                                </span>

                                                <span class="font-weight-bold">
                                                  {{
                                                    evaluation.limit_date_formatted
                                                  }}
                                                </span>
                                              </p>

                                              <p
                                                class="text-body-1 ma-0 mt-2 font-weight-medium"
                                              >
                                                Este es un perfil en modalidad
                                                <span
                                                  class="font-weight-bold text-lowercase info--text"
                                                  >{{
                                                    evaluation.is_group_activity
                                                  }}</span
                                                >.
                                              </p>

                                              <v-btn
                                                :color="
                                                  colors[item.subject.id % 12]
                                                "
                                                elevation="2"
                                                outlined
                                                class="mt-3"
                                                @click="
                                                  goToSubjectEvaluations(
                                                    evaluation.subject_teacher_id
                                                  )
                                                "
                                              >
                                                Ver perfiles
                                              </v-btn>
                                            </v-card>
                                          </v-menu>
                                        </v-col>
                                        <!-- end::Evaluation name -->
                                        <v-col class="px-0 py-1 mt-2" cols="4">
                                          <!-- begin:: SCOREVIEWER -->
                                          <ScoreViewer
                                            :isUpdated="true"
                                            :score="evaluation.final_score"
                                            :replacementScore="0"
                                            :summaryScore="true"
                                            :studentStatus="1"
                                            :subjectType="
                                              item.subject
                                                .subject_evaluation_scale
                                                .subject_type_id
                                            "
                                          ></ScoreViewer>
                                          <!-- end:: SCOREVIEWER -->
                                        </v-col>
                                      </v-row>
                                      <v-divider></v-divider>
                                      <v-row
                                        class="d-flex justify-center align-center pb-2 pb-lg-0"
                                        v-for="(
                                          subEvaluation, subEvaluationIndex
                                        ) in evaluation.sub_evaluations"
                                        :key="subEvaluationIndex"
                                      >
                                        <!-- begin::subEvaluation name -->
                                        <v-col class="pa-1" cols="8">
                                          <v-menu
                                            open-on-click
                                            bottom
                                            nudge-bottom="5px"
                                            offset-y
                                            :close-on-content-click="false"
                                            content-class="elevation-0"
                                          >
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <p
                                                v-bind="attrs"
                                                v-on="on"
                                                class="ml-3 ma-0 text-body-1 text-left text-sm-left font-weight-medium"
                                              >
                                                {{ subEvaluationIndex + 1 }}.
                                                {{
                                                  subEvaluation.evaluation_type
                                                }}
                                                ({{
                                                  subEvaluation.percent_formatted
                                                }})
                                              </p>
                                            </template>

                                            <v-card
                                              class="pa-2 ma-0 elevation-20"
                                              style="
                                                width: 250px !important;
                                                border: 1px solid gray;
                                              "
                                            >
                                              <p
                                                class="text-body-2 ma-0 font-weight-medium"
                                              >
                                                Fecha límite:
                                                <span
                                                  class="font-weight-bold"
                                                  >{{
                                                    subEvaluation.limit_date_formatted
                                                  }}</span
                                                >
                                              </p>
                                              <p
                                                class="text-body-1 ma-0 mt-2 font-weight-medium"
                                              >
                                                Esta es una actividad de un
                                                perfil <br />
                                                en modalidad
                                                <span
                                                  class="font-weight-bold text-lowercase info--text"
                                                  >{{
                                                    evaluation.is_group_activity
                                                  }}</span
                                                >.
                                              </p>
                                            </v-card>
                                          </v-menu>
                                        </v-col>
                                        <!-- end::subEvaluation name -->
                                        <v-col class="pa-0 pa-sm-1" cols="4"
                                          ><!-- begin:: SCOREVIEWER for subEvaluation -->
                                          <ScoreViewer
                                            :isUpdated="true"
                                            :score="subEvaluation.score"
                                            :replacementScore="0"
                                            :summaryScore="true"
                                            :studentStatus="1"
                                            :subjectType="
                                              item.subject
                                                .subject_evaluation_scale
                                                .subject_type_id
                                            "
                                          ></ScoreViewer
                                          ><!-- end:: SCOREVIEWER for subEvaluation-->
                                        </v-col>
                                      </v-row>
                                    </v-banner>
                                  </v-col>
                                  <!-- begin::fallback for when there isn't any evaluation -->
                                  <v-col class="px-0 px-sm-4" v-else>
                                    <v-banner
                                      outlined
                                      rounded
                                      class="pa-0 mb-2"
                                    >
                                      <p
                                        class="text-center ma-0 text-body-1 text-muted"
                                      >
                                        Aún no se han registrado perfiles en
                                        esta fase.
                                      </p>
                                    </v-banner>
                                  </v-col>
                                  <!-- end::fallback for when there isn't any evaluation -->
                                </v-row>
                              </v-container>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                        <!-- begin:: Stages -->
                      </v-col>
                      <!-- begin::fallback for when there isn't any stage -->
                      <v-col class="px-4" v-else>
                        <v-banner outlined rounded class="pa-0 mb-2">
                          <p
                            class="text-center ma-0 text-h6 opacity-70 font-weight-normal"
                          >
                            Aún no se ha registrado ninguna etapa
                          </p>
                        </v-banner>
                      </v-col>
                      <!-- end::fallback for when there isn't any stage -->
                    </v-row>
                    <!-- end::Collapsible stages container  -->
                  </v-card>
                  <!-- begin:: Evaluations separated by stages -->
                </v-container>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <!-- end::Information card -->
    </v-col>
  </v-row>
</template>
<!-- begin::EvaluationCard component-->

<script>
import ScoreViewer from "@/components/elements/sub_evaluation_scores/ScoreViewer.vue";
import { mapGetters } from "vuex";
export default {
  name: "SubjectStageSummaryContainer",
  components: {
    ScoreViewer,
  },
  props: {
    evaluation: {
      type: Array,
    },

    selectedStudent: {
      type: Object,
    },
  },
  data() {
    return {
      panel: [],
    };
  },
  methods: {
    goToSubjectEvaluations(subject) {
      this.$router.push({
        path: "student_scores",
        query: {
          s: subject,
        },
      });
      this.$store.dispatch(SET_SUBJECT, subject);
    },

    stageType(subjectType) {
      if (this.selectedStudent?.grade_id > 3) {
        return subjectType == 2 ? "periodo" : "módulo";
      } else {
        return subjectType == 2 ? "trimestre" : "módulo";
      }
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
  },
};
</script>

<style>
.banner-title {
  white-space: pre-wrap !important;
}

@media (max-width: 374px) {
  .v-banner__wrapper {
    padding: 5px !important;
  }
  .banner-title {
    text-align: center !important;
  }
}

.stageIntroText {
  line-height: 20px !important;
}

.title {
  font-size: 1.2em !important;
}

.card-size {
  visibility: hidden !important;
}
</style>
